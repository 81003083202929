.options-header-container {
    width: 100%;
    color: black;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    /*top: 50px;*/
    /*use this if options header is placed below the products carousel*/
    /*z-index: 5;*/
}

.options-header-container.error {
    width: 100%;
    color: black;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
}

.accordion-summary-container {
    display: flex;
    padding: 0px 16px;
    background-color: #DFDFDF;
    
}

.location-menu-button-container {
    padding-left: 0;
    /*This negative margin counters the padding in the location - which we want to keep for when it is located in the categories carosel*/
    width: 50%;
    
}

.options-menu-button-container {    
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

/*.options-menu-button-container p {*/
/*    text-align: right;*/
/*}*/

.accordion-details-container {
    display: flex;
    justify-content: flex-end;
}

.expansion-icon-label {
    color: black;
    font-size: .875rem;
}

.expansion-icon {
    color: black;
}
