.full-menu-modal-container {
    display: flex;
    justify-content: center;
}

.full-menu-modal-title {
    text-align: center;
}

.full-menu-modal-content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.full-menu-modal-content-container  p {
    text-align: left;
    margin-top: 0;
}

.contact-support-message {
    font-size: .875rem;
    color: #323232;
}

.filters-sorting-section {
    min-width: 300px;
    width: 100%;
    display: flex;
    justify-content: space-around;

}

.sort-parameter,.sort-direction {
    width: 50%;

}

.sort-direction {
    margin-top: 12px;
}